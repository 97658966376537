import React from "react"
import { TextField, Button, Typography } from "@material-ui/core"
import { Redirect } from "react-router-dom"
import { PortalAppBar } from "../components"
import { GoogleLogin } from "react-google-login"
import { InsertComment, Group, Business } from "@material-ui/icons"

import "./styles/SignUpScreenStyles.scss"

type Props = {}
type State = { redirectLogin: boolean; redirectGames: boolean }

export class SignUpScreen extends React.Component<Props, State> {
  state: State = { redirectLogin: false, redirectGames: false }

  componentDidMount() {
    this.setState({
      redirectLogin: false,
      redirectGames: false,
    })
  }
  responseGoogle = (response: any) => {
    console.log(response)
  }
  signUp() {
    console.log("signing up")
    localStorage.setItem("token", "true")
    this.setState({ redirectGames: true })
  }

  redirectLogin() {
    this.setState({ redirectLogin: true })
  }
  render() {
    const { redirectGames, redirectLogin } = this.state
    if (redirectGames) {
      return <Redirect push to="/" />
    }
    if (redirectLogin) {
      return <Redirect push to="/login" />
    }
    return (
      <div>
        <PortalAppBar />
        <div className="landing-screen">
          <div className="content">
            <div className="left-side">
              <div className="left-title">
                {"Get immediate traction for\nyour business idea on Absurd."}
              </div>
              <div className="line-item">
                <InsertComment className="icon" />
                <div>
                  <span style={{ fontWeight: "bold" }}>Share </span> your best
                  ideas.
                </div>
              </div>
              <div className="line-item">
                <Group className="icon" />
                <div>
                  <span style={{ fontWeight: "bold" }}>Collaborate</span> with
                  local and global founders.
                </div>
              </div>
              <div className="line-item">
                <Business className="icon" />
                <div>
                  <span style={{ fontWeight: "bold" }}>Build</span> the best
                  future you can imagine.
                </div>
              </div>
            </div>
            <div className="right-side">
              {/* <GoogleLogin
                clientId="548066065806-t7q4i73io3afsvbenje1m8bgb1l697ag.apps.googleusercontent.com"
                buttonText="Sign in with Google"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={"single_host_origin"}
              /> */}
              <div className="right-header">
                <div className="title-line">
                  <div className="right-title">Sign Up</div>
                  <Button
                    className="redirect-option"
                    onClick={() => this.redirectLogin()}
                  >
                    Login
                  </Button>
                </div>
                <div className="right-subtitle">It's fast and easy.</div>
              </div>

              <form noValidate autoComplete="off">
                <div className="fields-container">
                  <div className="name-fields">
                    <TextField
                      className="text-field left-field"
                      placeholder="First Name"
                      variant="outlined"
                      margin="dense"
                    />
                    <TextField
                      className="text-field"
                      placeholder="Last Name"
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                  <TextField
                    className="text-field"
                    placeholder="Email"
                    variant="outlined"
                    margin="dense"
                  />
                  <TextField
                    className="text-field"
                    placeholder="New Password"
                    variant="outlined"
                    margin="dense"
                  />
                  <Button
                    color="secondary"
                    variant="contained"
                    className="button"
                    onClick={() => this.signUp()}
                  >
                    <Typography variant="h5">Sign Up</Typography>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SignUpScreen
