import React from "react"
import { Paper, Button, Typography, TextField } from "@material-ui/core"
import { Game } from "../types"
import { Receipt } from "@material-ui/icons"

import "./styles/MadlibMadnessStyles.scss"

type Props = {}
type State = { words: string[]; businessName: string; plan: string }

export class MadlibGame {
  public static getGame() {
    let game: Game = {
      title: "Madlib Madness",
      description:
        "Build a business that incorporates randomly generated words into its business model.",
      path: "madness",
      icon: Receipt,
    }
    return game
  }
}

export default class MadlibMadness extends React.Component<Props, State> {
  state = { words: [], businessName: "", plan: "" }

  componentDidMount() {
    this.setState({ words: this.generateWords(), businessName: "", plan: "" })
  }

  generateWords() {
    let words: string[] = []
    while (words.length < 3) {
      let word = wordList[Math.floor(Math.random() * wordList.length)]
      if (!words.includes(word)) {
        words.push(word)
      }
    }
    return words
  }

  render() {
    const wordList = this.state.words.map((word) => <li key={word}>{word}</li>)
    const { businessName, plan } = this.state
    return (
      <div className="madlib-madness">
        <div className="prompt">
          Incorporate these words into your new business model:
        </div>

        <div className="word-list">{wordList}</div>
        <div className="button-container">
          <Button
            className="save-button"
            variant="contained"
            disabled={businessName === "" || plan === ""}
          >
            <div>Save</div>
          </Button>
          <Button
            color="primary"
            className="save-button"
            variant="contained"
            disabled={businessName === "" || plan === ""}
          >
            <div>Save & Post</div>
          </Button>
        </div>
        <Paper className="result-container">
          <Typography variant="h5" component="h3" className="paper-title">
            {businessName || "The Brilliant Name"}
          </Typography>
          <Typography component="p" className="paper-body">
            {plan || "The master plan."}
          </Typography>
        </Paper>
        <div className="input-container">
          <TextField
            id="outlined-basic"
            label="The Brilliant Name"
            variant="outlined"
            fullWidth
            onChange={(event) =>
              this.setState({ businessName: event.target.value })
            }
          />
        </div>
        <div className="input-container">
          <TextField
            id="outlined-basic"
            label="The Master Plan"
            multiline
            rows="8"
            variant="outlined"
            fullWidth
            onChange={(event) => this.setState({ plan: event.target.value })}
          />
        </div>
      </div>
    )
  }
}

const wordList = [
  "food",
  "rat",
  "pull",
  "wall",
  "reward",
  "riddle",
  "trick",
  "drain",
  "school",
  "toys",
  "friend",
  "smash",
  "bit",
  "noise",
  "grade",
  "floor",
  "flavor",
  "lunchroom",
  "thing",
  "ticket",
  "range",
  "wealth",
  "language",
  "reason",
  "cannon",
  "curve",
  "shock",
  "egg",
  "smell",
  "request",
  "error",
  "rate",
  "oven",
  "zipper",
  "baseball",
  "morning",
  "stocking",
  "move",
  "impulse",
  "sound",
  "writing",
  "hole",
  "route",
  "hands",
  "glass",
  "uncle",
  "spade",
  "clover",
  "ink",
  "umbrella",
  "rifle",
  "fuel",
  "crate",
  "pets",
  "donkey",
  "lunch",
  "stitch",
  "bead",
  "war",
  "harmony",
  "spy",
  "flock",
  "shoe",
  "spoon",
  "boy",
  "drink",
  "hat",
  "driving",
  "camera",
  "authority",
  "connection",
  "finger",
  "existence",
  "receipt",
  "cheese",
  "condition",
  "soap",
  "calendar",
  "scissors",
  "birth",
  "knife",
  "position",
  "frame",
  "yoke",
  "sheet",
  "rings",
  "man",
  "limit",
  "fear",
  "border",
  "fly",
  "suggestion",
  "substance",
  "rabbits",
  "rest",
  "trail",
  "screw",
  "spot",
  "jeans",
  "harbor",
]
