import React from "react"
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { Redirect } from "react-router-dom"
import "./styles/AppBarStyles.scss"

type AppBarProps = { title: string; shouldNavigate: boolean }

type State = {
  redirectHome: boolean
  redirectSignUp: boolean
  authToken: string
}

export default class ButtonAppBar extends React.Component<AppBarProps, State> {
  static defaultProps = { title: "", shouldNavigate: true }

  state: State = {
    redirectHome: false,
    redirectSignUp: false,
    authToken: localStorage.getItem("token") || "",
  }

  componentDidMount() {
    console.log("mounting")
    this.setState({
      redirectHome: false,
      redirectSignUp: false,
      authToken: localStorage.getItem("token") || "",
    })
  }

  redirectHome() {
    this.setState({ redirectHome: true })
  }

  logOut() {
    localStorage.setItem("token", "")
    this.setState({ redirectSignUp: true })
  }

  render() {
    console.log("here in render")
    const { title, shouldNavigate } = this.props
    const { redirectHome, redirectSignUp, authToken } = this.state
    if (redirectHome && shouldNavigate) {
      console.log("navigating")
      return <Redirect push to="/" />
    }
    if (authToken === "" || redirectSignUp) {
      return <Redirect push to="/sign-up" />
    }
    return (
      <div className="app-bar">
        <AppBar color="primary">
          <Toolbar variant="dense">
            <img
              height={28}
              width={28}
              src={process.env.PUBLIC_URL + "lowA.png"}
              alt="absurd logo"
              style={{ borderRadius: 2, marginLeft: 20 }}
              onClick={() => this.redirectHome()}
            />
            <Typography variant="h4" className="title">
              {title}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => this.logOut()}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}
