import React from "react"
import { Switch } from "react-router-dom"
import Route from "./Route"
import {
  SignUpScreen,
  LoginScreen,
  GameSelectionScreen,
  GamingScreen,
} from "../screens"

export default function Routes() {
  return (
    <Switch>
      {/* <Route path="/" exact component={SignUpScreen} /> */}

      <Route path="/madness" component={GamingScreen} />
      <Route path="/login" component={LoginScreen} />
      <Route path="/sign-up" component={SignUpScreen} />
      <Route path="/" component={GameSelectionScreen} />
    </Switch>
  )
}
