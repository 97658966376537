import React from "react"
import { Router } from "react-router-dom"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

import "./App.scss"
import history from "./services/history"
import Routes from "./routes"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#22b7f2",
      dark: "#5441fd",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f26522",
      light: "#FDA941",
    },
  },
})

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="app-body">
        <Router history={history}>
          <Routes />
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default App
