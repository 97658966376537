import React from "react";

import "./styles/GameButtonStyles.scss";
import { Game } from "../types";

type Props = { game: Game; onClick: any };
type State = {};

export default class GameButton extends React.Component<Props, State> {
  render() {
    return (
      <div className="game-button" onClick={this.props.onClick}>
        <div className="text">
          <div className="title">{this.props.game.title}</div>
          <div className="description">{this.props.game.description}</div>
        </div>
        <div className="icon-container">
          <this.props.game.icon className="icon" color="secondary" />
        </div>
      </div>
    );
  }
}
