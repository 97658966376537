import React from "react";

import "./styles/IdeaButtonStyles.scss";
import { Idea } from "../types";

type Props = { idea: Idea; onClick: any };
type State = {};

export default class IdeaButton extends React.Component<Props, State> {
  render() {
    return (
      <div className="game-button" onClick={this.props.onClick}>
        <div className="text">
          <div className="title">{this.props.idea.businessName}</div>
          <div className="description">{this.props.idea.plan}</div>
        </div>
      </div>
    );
  }
}
