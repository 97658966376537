import React from "react"

import "./styles/GamingScreenStyles.scss"
import { MadlibMadness, MadlibGame, AppBar } from "../components"

type Props = { location: any }
type State = { gameType: string }

class GamingScreen extends React.Component<Props, State> {
  state: State = { gameType: "" }

  componentDidMount() {
    this.setState({ gameType: this.props.location.pathname })
  }

  render() {
    let game = null
    let title = null
    let icon = null
    let gameValues = null
    switch (this.state.gameType) {
      default:
        gameValues = MadlibGame.getGame()
        game = <MadlibMadness />
        title = gameValues.title
        icon = <gameValues.icon className="icon" color="secondary" />
    }
    return (
      <div>
        <AppBar />
        <div className="gaming-screen">
          <div className="title-bar">
            <div className="title">{title}</div>
            <div>{icon}</div>
          </div>
          <div className="game-container">{game}</div>
        </div>
      </div>
    )
  }
}

export default GamingScreen
