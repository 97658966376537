import React from "react"
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  TextField,
} from "@material-ui/core"
import { Redirect } from "react-router-dom"
import "./styles/AppBarStyles.scss"
import "./styles/PortalAppBarStyles.scss"

type Props = {}
type State = { authToken: string; redirectGames: boolean }

export default class PortalButtonAppBar extends React.Component<Props, State> {
  state: State = {
    authToken: localStorage.getItem("token") || "",
    redirectGames: false,
  }

  componentDidMount() {
    this.setState({
      authToken: localStorage.getItem("token") || "",
      redirectGames: false,
    })
  }
  login() {
    localStorage.setItem("token", "true")
    this.setState({ redirectGames: true })
  }
  render() {
    const { authToken, redirectGames } = this.state
    if (authToken !== "" || redirectGames) {
      return <Redirect push to="/" />
    }
    return (
      <div className="app-bar">
        <AppBar color="primary" className="portal-app-bar">
          <Toolbar style={{ maxWidth: "980px", flex: 1 }}>
            <Typography variant="h4" className="title">
              Absurd
            </Typography>

            <div className="login-bar">
              <TextField
                className="text-field"
                placeholder="Email"
                variant="outlined"
                margin="dense"
              />
              <TextField
                className="text-field"
                placeholder="Password"
                variant="outlined"
                margin="dense"
              />
              <Button
                className="login-button"
                color="secondary"
                variant="contained"
                onClick={() => this.login()}
              >
                Login
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}
