import React from "react"
import { Redirect } from "react-router-dom"

import "./styles/GameSelectionScreenStyles.scss"
import { GameButton, IdeaButton, AppBar, MadlibGame } from "../components"
import { Game, Idea } from "../types"

type Props = {}
type State = { selectedGame: string }

export default class GameSelectionScreen extends React.Component<Props, State> {
  state: State = { selectedGame: "" }

  componentDidMount() {
    this.setState({ selectedGame: "" })
  }

  render() {
    if (this.state.selectedGame !== "") {
      return <Redirect push to={this.state.selectedGame} />
    }
    return (
      <div>
        <AppBar shouldNavigate={false} />
        <div className="game-selection">
          <div className="selection-header">Dumb idea time!</div>
          <GameButton
            game={games[0]}
            onClick={() => this.setState({ selectedGame: games[0].path })}
          />
        </div>
        <div className="idea-selection">
          <div className="selection-header">Collected Ideas</div>
          <IdeaButton
            idea={ideas[0]}
            onClick={() => this.setState({ selectedGame: games[0].path })}
          />
        </div>
      </div>
    )
  }
}

const games: Game[] = new Array(MadlibGame.getGame())

const ideas: Idea[] = new Array({
  businessName: "Rent A Swag",
  plan:
    "By renting my adolescent-sized clothing to young American children in my hometown of Pawnee Indiana, I will put my assets " +
    "to better use than they are currently favoring me with. The business model will primarily depend upon upscale middle " +
    "American families with parents who don't know how to say no to their children and children who believe that to fit in with " +
    "the cool kids they need fancy clothing.",
})
