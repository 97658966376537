import React from "react"
import { TextField, Button, Typography } from "@material-ui/core"
import { Redirect } from "react-router-dom"
import { PortalAppBar } from "../components"

import "./styles/SignUpScreenStyles.scss"

type Props = {}
type State = { redirectSignUp: boolean; redirectGames: boolean }

export class LoginScreen extends React.Component<Props, State> {
  state: State = { redirectSignUp: false, redirectGames: false }

  componentDidMount() {
    this.setState({
      redirectSignUp: false,
      redirectGames: false,
    })
  }
  responseGoogle = (response: any) => {
    console.log(response)
  }
  login() {
    console.log("logging in")
    localStorage.setItem("token", "true")
    this.setState({ redirectGames: true })
  }
  redirectSignUp() {
    this.setState({ redirectSignUp: true })
  }

  render() {
    const { redirectSignUp, redirectGames } = this.state

    if (redirectSignUp) {
      return <Redirect push to="/sign-up" />
    }
    if (redirectGames) {
      return <Redirect push to="/" />
    }
    return (
      <div>
        <PortalAppBar />
        <div className="landing-screen">
          <div className="content">
            <div className="right-side">
              <div className="right-header">
                <div className="title-line">
                  <div className="title">Login</div>
                  <Button
                    className="redirect-option"
                    onClick={() => this.redirectSignUp()}
                  >
                    Sign up
                  </Button>
                </div>
              </div>

              <form noValidate autoComplete="off">
                <div className="fields-container">
                  <TextField
                    className="text-field"
                    placeholder="Email"
                    variant="outlined"
                    margin="dense"
                  />
                  <TextField
                    className="text-field"
                    placeholder="Password"
                    variant="outlined"
                    margin="dense"
                  />
                  <Button
                    color="secondary"
                    variant="contained"
                    className="button"
                    onClick={() => this.login()}
                  >
                    <Typography variant="h5">Login</Typography>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginScreen
